import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

//models
import { AutocompleteModel } from '../molds/AutocompleteModel';

@Component
export default class Autocomplete extends Vue { 
	@Prop({ required: true }) autocomplete!: AutocompleteModel

	value = ""
	
	@Watch('value') 
	inputChanged(value: string) {
    this.$emit("value", value);
	}

	blur(){
		this.$emit("blur");
	}
	
	onSelected(value: string){
		this.$emit("selected", value);
	}

	get filteredDataArray() {
		return this.autocomplete.options.filter((option) => {
			return option
				.toString()
				.toLowerCase()
				.indexOf(this.value.toLowerCase()) >= 0
		})
	}

}
