import { CarouselOptions } from './../molds/CarouselOptions';
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Carousel extends Vue { 
	@Prop() href!: string
	@Prop() routerLink!: string
	@Prop() indicators!: boolean
	@Prop({required: true}) carouselOptions!: CarouselOptions

	clickElement(index: number): void {
		// this.$emit("itemClick", this.carouselOptions.items[index])		
		
		if(this.carouselOptions.items[index].routerLink) this.$router.push(this.carouselOptions.items[index].routerLink || '/')
		else if(this.carouselOptions.items[index].href) window.open(this.carouselOptions.items[index].href, '_blank')
	}
}
