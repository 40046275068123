import { Component, Prop, Vue } from 'vue-property-decorator'
import { IconListOptions, IconListItem } from '../molds/IconListItem'

import { WButton, SizedBox, WColumns, WColumn } from '@/components/wrappers'

@Component({
	components:{
		WButton, 
		SizedBox,
		WColumns, WColumn
	}
})
export default class IconList extends Vue { 
	@Prop() iconListOptions!: IconListOptions
	@Prop() selectItem!: number

	emitSelected(item: IconListItem, index: number){
		this.$emit("indexSelected", index)
		this.$emit("itemSelected", item)
	}

	select(index: number): string {
		if(this.selectItem == index){
			this.selectItem = index
			return "item-select"
		} 
		return ""
	}
	
}
