import { render, staticRenderFns } from "./WImage.vue?vue&type=template&id=f204b638&scoped=true&"
import script from "./wImage.ts?vue&type=script&lang=ts&"
export * from "./wImage.ts?vue&type=script&lang=ts&"
import style0 from "./WImage.vue?vue&type=style&index=0&id=f204b638&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f204b638",
  null
  
)

export default component.exports