import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Wicon extends Vue { 
	@Prop() icon!: string
	@Prop() size!: number
	@Prop() color!: string
	@Prop() label!: string
	@Prop() type!: string

	getIcon(): string {
		let icon = this.icon

		if(this.type){
			switch (this.type.toLocaleLowerCase()) {
				case 'mdi':
					icon = `mdi mdi-${this.icon}`
				break
				case 'fas':
					icon = `fas fa-${this.icon}`
				break
				case 'far':
					icon = `far fa-${this.icon}`
				break
				case 'fa':
					icon = `fa fa-${this.icon}`
				break
			}
		}		
		return icon
	}

}
