import { Component, Vue } from 'vue-property-decorator';

import { 
  wIcon,
  WButton,
 } from '@/components/wrappers';

@Component({
  
  components: {
    wIcon,
    WButton,
  }
})

export default class Modal extends Vue { }
