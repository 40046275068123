import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
	components:{
		Arrow: () => import('@/components/wrappers/arrow/Arrow.vue')
	}
})
export default class WSubtitle extends Vue {
	@Prop() hr!: boolean
	@Prop() arrow!: boolean
}
