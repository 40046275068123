import { Component, Vue, Prop } from 'vue-property-decorator'

//model
import { PainelIconItem } from '@/molds/ListPanelIconsModel';

//warppets
import { wIcon, Autocomplete, SizedBox, WColumns, WColumn, IconList, Modal } from '@/components/wrappers';

@Component({
	components:{
		wIcon,
		Modal,
		IconList,
		SizedBox,
		Autocomplete,
		WColumns, WColumn,
	}
})
export default class NotificationList extends Vue { 
	@Prop() painelIconItens!: PainelIconItem[]

	emitConfirm(item: PainelIconItem): void {		
		this.$emit('emitConfirm', item)
	}

	emitRefuse(item: PainelIconItem): void {
		this.$emit('emitRefuse', item)
	}
}