import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WButton extends Vue { 
	@Prop () type!: string
	@Prop () theme!: boolean
	@Prop () iconLeft!: string
	@Prop () iconRight!: string
	@Prop () disabled!: boolean
	@Prop () loading!: boolean

	submit = "button"

	//cores do botão
	success = 'hsl(141, 71%,  48%)';
	danger = 'hsl(14,  100%, 53%)';
	warning = 'hsl(48,  100%, 67%)';

	mounted(){
		this.configSubmit()
		// this.configColorButton()		
	}

	getClass(): string {
		if(this.disabled != null){
			return 'is-disabled'
		}
		return 'effects'
	}

	configSubmit(): void {
		if(this.type != null && this.type.toLowerCase().includes('submit')){
			this.submit = 'submit' 
		}
	}

	configColorButton(): void {
		const root = document.documentElement
		let color;

		if(this.type != null && this.type.toLowerCase().includes('is-')){

			switch (this.type.toLocaleLowerCase()) {
				case 'is-success':
					color = this.success
				break;
				case 'is-danger':
					color = this.danger
				break;
				case 'is-warning':
				case 'is-warn':
					color = this.warning
				break;
			}

			if(color != null){				
				root.style.setProperty('$color', color);
			}
			
		}

	}
}