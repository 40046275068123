import { TableOptions } from '@/components/wrappers/molds/TableOptions';
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WTable extends Vue { 
	@Prop() table!: TableOptions

	itemSelect!: any

	total = this.table.wolfApiData?.totalitems
	current = this.table.wolfApiData?.page
	// perPage = 10
	rangeBefore = 3
	rangeAfter = 1
	order = 'is-centered'
	size = '3'
	isSimple = false
	isRounded = false
	prevIcon = 'chevron-left'
	nextIcon = 'chevron-right'

	eventTable(param: number){
		this.current = param
		this.$emit('page', param)
	}

	get dataTable(): any {
		if(this.table.wolfApiData?.list){
			return this.table.wolfApiData?.list
		}else if(this.table.data){
			return this.table.data
		} return []
	}

	get selected() {
		return this.itemSelect
	}

	set selected(element) {
		this.itemSelect = element;
		this.$emit('selected', element)
	}
}
