import { Component, Prop, Vue } from 'vue-property-decorator'

//wrappers
import { wIcon } from '@/components/wrappers';

@Component({
	components:{
		wIcon
	}
})
export default class MiniCardIcon extends Vue {
	@Prop() href!: string
	@Prop() hoverColor!: string
	@Prop() routerLink!: string
	@Prop({required: true}) title!: string

	goToLink(): void {
		if(this.routerLink) this.$router.push(this.routerLink)
		else if(this.href) window.open(this.href, '_blank');
	}

 }
