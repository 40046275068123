import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Container extends Vue {
  @Prop() size!: string
  
  getClass(){
    if(this.size == undefined){
      return '';
    }
    return `is-${this.size}`;
  }
	
}
