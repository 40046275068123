import { Component, Prop, Vue } from 'vue-property-decorator'

import { wIcon } from '@/components/wrappers';

@Component({
	components:{
		wIcon
	}
})
export default class Arrow extends Vue { 
	@Prop({required: true}) type!: string
	@Prop() action!: string

	arrowAction(): void {
		if(this.action == null){
			if (this.type == "left"){
				this.$router.back()
			}else { 
				this.$router.forward()
			}
		}
	}

}
