import { Component, Vue } from 'vue-property-decorator'
//config
import { 
	Version, 
	PortalSite, 
	PortalName, 
} from '@/config'

@Component
export default class Footer extends Vue {

	varsion = Version()
	portalName = PortalName
	portalSite = PortalSite
	
	getYear(): number{
		return new Date().getFullYear()
	}
}
