import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

@Component({})
export default class WTabs extends Vue {
	
	index = 0
	elementsChilds: any

	mounted(){
		this.startIndex()
	}

	tabChange(index: number): void {
		this.$emit('tabChange', index)
	}

	startIndex() {
		if(this.$route.query.index != null){
			const index = parseInt(`${this.$route.query.index}`);
			if(!isNaN(index)){
				this.index = index
			}
		}
	}

	@Watch('$route.query.index')
	watchQueryIndex(){
		this.startIndex();		
	}

	@Watch('index')
	watchIndex(newVal: any) {
		this.$router.push({query: {index: newVal}}).catch(error => {
			if (error.name != "NavigationDuplicated") {
				throw error;
			}
		})
	}

  @Watch('$slots', {
    immediate: true, 
    deep: true 
  })
  projectChanged(newVal: any) {
    this.elementsChilds = newVal
  }

}
